<template>
    <div @click="noClose" v-if="info">
        <div v-if="info.amount_id">
            <div class="user-head flex">
                <div class="flex flex-1 flex-y">
                    <div class="user-icon flex flex-x flex-y" @click="returnFun">
                        <i class="iconfont">&#xe62d;</i>
                    </div>
                    <div class="user-input flex-1 flex flex-x">
                        <div class="user-search">
                            <div class="search flex flex-y flex-x">
                                <van-icon name="search" />
                            </div>
                            <input type="text" v-model="accountText"/>
                        </div>
                    </div>
                </div>
                <!-- <div :class="{active:add}" class="user-add flex flex-x flex-y">
                    <div class="icon1" @click="addk">
                        <van-icon name="add-o" />
                    </div>
                    <div class="icon2">
                        <van-icon name="close" />
                    </div>
                    <ul class="add-right">
                        <li class="flex" @click="smadd">
                            <div class="add-icon flex flex-x flex-y">
                                <van-icon name="scan" />
                            </div>
                            <div>扫码添加用户</div>
                        </li>
                    </ul>
                </div> -->
            </div>
            <div class="user-fill"></div>
            <div class="user-info flex flex-y" v-if="info">
                <div class="info-img">
                    <img :src="info.avatarUrl" v-if="info.avatarUrl"/>
                    <img v-else src="../../../../images/user.png"/>
                </div>
                <div class="flex-1 flex flex-y">
                    <div>
                        <div class="info-name">{{info.name||info.nikeName}}</div>
                        <div class="info-red">自己</div>
                    </div>
                </div>
                <div class="info-button flex">
                    <a href="/redEnvelope/reportList" class="look">查看报告</a>
                    <div class="look red" @click="()=>ownFun(info.amount_id)">开始评估</div>
                </div>
            </div>
            <vueTime :returnFun="TimeFun" />
            <div class="user-yoo flex" v-if="tongji">
                <div class="flex-1">
                    <div class="user-num">{{tongji.custom_count}}</div>
                    <div>总客户数</div>
                </div>
                <div class="flex-1">
                    <div  class="user-num">{{tongji.test_count}}</div>
                    <div>总评估次数</div>
                </div>
                <div class="flex-1">
                    <div  class="user-num">{{tongji.tace_custom_count}}</div>
                    <div>他测客户数</div>
                </div>
                <div class="flex-1">
                    <div  class="user-num">{{tongji.tace_test_count}}</div>
                    <div>他测次数</div>
                </div>
                <div class="flex-1">
                    <div  class="user-num">{{tongji.hongbao_test_count}}</div>
                    <div>红包评估次数</div>
                </div>
            </div>
            <div>
                <div class="user-nav flex">
                    <div class="flex-1">客户列表</div>
                    <div class="user-nav-icon flex flex-x flex-y" @click="smadd">
                        <van-icon name="plus" />
                    </div>
                </div>
                <div class="user-info flex flex-y" v-for="(item,index) in custom_list" v-bind:key="index">
                    <div class="info-img">
                        <img :src="item.avatar_url" v-if="item.avatar_url"/>
                        <img v-else src="../../../../images/user.png"/>
                    </div>
                    <div class="flex-1 flex flex-y">
                        <div>
                            <div class="info-name">{{item.name||item.nikeName}}</div>
                        </div>
                    </div>
                    <div class="info-button flex">
                        <a :href="`/redEnvelope/reportList/${item.id}`" class="look">查看报告</a>
                        <div class="look red" @click="()=>estimateFun(item.id)">开始评估</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="tit-title flex flex-y flex-x">
            <div>
                <div>您还不是业务员，无法使用此功能!</div>
                <div>请联系您的健康咨询师</div>
            </div>
        </div>
    </div>
</template>
<script>
    import vueTime from '@/components/time';
    import { mapState, mapActions, mapMutations } from "vuex";
    let sumt;
    export default {
        name: 'list2',
        data(){
            return {
                add:false,
                start_time:'',
                end_time:'',
                account:''
            }
        },
        components:{
            vueTime
        },
        created:function(){
            this.axiosMain({});
            this.axiosCustomList({
                page:1,
                page_size:300
            })
        },
        methods: {
            returnFun(){
                this.$router.back('-1');
            },
            TimeFun(time){
                this.start_time = time.start1;
                this.end_time = time.end1;
                this.axiosTongji({
                    start_time:time.start1,
                    end_time:time.end1,
                    type:1
                });
            },
            noClose(){
                this.add = false;
            },
            addk(e){
                e.stopPropagation();
                this.add = true;
            },
            smadd(e){
                e.stopPropagation();
                this.$router.push({
                    name:'qr_code',
                    params: {
                        id:this.info.amount_id
                    }
                });
            },
            estimateFun(id){
                this.axiosRedCanReport({
                    data:{
                        use_hongbao:0
                    },
                    fun:(data)=>{
                        this.$router.push({
                            path:'/confirmAssess/15',
                            query:{
                                uid:id
                            }  
                        });
                    }
                })
            },
            ownFun(id){
                this.axiosRedCanReport({
                    data:{
                        use_hongbao:0
                    },
                    fun:(data)=>{
                        this.$router.push({
                            path:'/confirmAssess/15',
                            query:{
                                uid:this.info.id
                            }
                        });
                    }
                })
            },
            ...mapActions('profile',['axiosMain']),
            ...mapActions('redEnvelope',['axiosCustomList','axiosTongji','axiosRedCanReport']),
        },
        computed:{
            accountText:{
                get() {
                    return this.account;
                },
                set(value){
                    this.account = value;
                    let _this = this;
                    clearTimeout(sumt);
                    sumt = setTimeout(function(){
                        _this.axiosCustomList({
                            kwywords:value,
                            page:1,
                            page_size:10
                        })
                    },1000);
                }
            },
            ...mapState('profile',['info']),
            ...mapState('redEnvelope',['tongji','custom_list']),
        },
    }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'list';
</style>